import useIsMobile from "@/hooks/useIsMobile";
import dynamic from "next/dynamic";
const HomePage = dynamic(() => import('@/screens/Homepage'));
const PageLayout = dynamic(() => import('@/screens/Layout/PageLayout'));
import { useEffect, useState } from "react";

export default function Home({
  breakingNewsData = null,
  homepageData = null,
  shortVideoData = null,
  topicListData = null,
  itemListData = null,
  statesNewsListData = null,
  electionLiveData = null,
  liveBlogData = null,
  constituencyList = null,
}) {
  const [liveVideo, setLiveVideo] = useState(false);
  const isMobileDevice = useIsMobile();
  useEffect(() => {
    setTimeout(async () => {
      try {
        const { getTakLiveVideo } = await import("@/handlers/liveVideo");
        const { data } = await getTakLiveVideo();
        if (data?.Event?.length > 0) {
          //* filter out live video by eventId = NEXT_PUBLIC_TAK_LIVE_ID if found then set true
          const live = data?.Event?.filter(
            (item) => item?.event_id === process.env.NEXT_PUBLIC_TAK_LIVE_ID
          );
          if (live?.length > 0) {
            let object = {
              live_id: live[0]?.event_id,
              livetv_title: live[0]?.event_title,
              livetv: live[0]?.embedded_url,
              thumbnail: live[0]?.thumbnail,
            };
            setLiveVideo(object);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  }, []);
  return (
    <>
      <PageLayout
        rightSideBarData={homepageData}
        isMobileDevice={isMobileDevice}
        topicListData={topicListData}
        liveVideoData={liveVideo}
        electionLiveData={electionLiveData}
      >
        <HomePage
          breakingNewsData={breakingNewsData}
          homepageData={homepageData}
          shortVideoData={shortVideoData}
          isMobileDevice={isMobileDevice}
          liveVideoData={liveVideo}
          itemListData={itemListData}
          statesNewsListData={statesNewsListData }
          electionLiveData={electionLiveData}
          liveBlogData={liveBlogData}
          constituencyList={constituencyList}
        />
      </PageLayout>
    </>
  );
}

export const getServerSideProps = async (ctx) => {
  const { logger } = await import("@/utils/logging/log-util");
  try {
    const { getMasterConfig } = await import("@/handlers/masterConfig");
    const { getRedisApiKey, fetchRedisWrapper } = await import(
      "@/handlers/redisKey"
    );

    const { setPageTTL } = await import("@/utils/setPageTTL");
    const { getBreakingNews } = await import("@/handlers/breakingNews");
    const { getHomepage } = await import("@/handlers/home");
    const { getShortsVideoList } = await import("@/handlers/shorts");
    const { getTopicList } = await import("@/handlers/topic");
    const { extractTopics } = await import("@/utils/topicList");
    const { getContentByType, getStatesData, getAutoLiveBlog } = await import(
      "@/handlers/content"
    );
    const { getElectionVideoData } = await import("@/handlers/liveVideo");

    let masterConfigData = await getMasterConfig();
    let electionConfigData = null;
    const electionConfig = await fetch(
      `${
        process.env.BASE_PATH + process.env.API_PREFIX
      }/staticconfig?id=masterconfig`
    ).then(async (res) => {
      return await res.json();
    });
    electionConfigData = electionConfig?.data?.delhi_election;

    //* get redis api key for each api
    let breakingNewsRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_ROS",
      params: { api: "breakingnews", display: 5 },
    });
    let homeRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_HOME",
    });
    let shortsRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_ROS",
      params: { api: "shortvideodatalisting", page: 0, display: 10 },
    });
    let topicListRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_ROS",
      params: { api: "topiclisting_", page: 0, display: 50 },
    });

    let contentListRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_ROS",
      params: { api: "newslist", type: "all", page: 0, display: 20 },
    });
    let categoryListRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_ROS",
      params: {
        api: "getfeaturedcontent",
        lang: "uptak,mbtak",
      },
    });
    let electionLiveRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_ROS",
      params: { api: "staticconfig", id: "home_livetv_config" },
    });
    const latestLiveRedisKey = getRedisApiKey({
      masterConfigData: masterConfigData,
      redisPrefix: "PREFIX_REDIS_ROS",
      params: {
        api: "newslist",
        page: 0,
        display: 48,
        id: process.env.AUTO_LIVE_BLOG,
        auto_blog: "1",
      },
    });

    let [
      breakingNewsData,
      homepageData,
      shortVideoData,
      topicListData,
      itemListData,
      statesNewsListData,
      electionLiveData,
      liveBlogData,
    ] = await Promise.all([
      fetchRedisWrapper(breakingNewsRedisKey, () =>
        getBreakingNews({ display: 5 })
      ),
      fetchRedisWrapper(homeRedisKey, () => getHomepage()),
      fetchRedisWrapper(shortsRedisKey, () =>
        getShortsVideoList({ page: 0, display: 10 })
      ),
      fetchRedisWrapper(topicListRedisKey, () => getTopicList()),
      fetchRedisWrapper(contentListRedisKey, () =>
        getContentByType({ type: "all", page: 0, display: 20 })
      ),
      fetchRedisWrapper(categoryListRedisKey, () =>
        getStatesData({
          lang: "uptak,mbtak",
        })
      ),
      fetchRedisWrapper(electionLiveRedisKey, () => getElectionVideoData()),
      fetchRedisWrapper(latestLiveRedisKey, () =>
        getAutoLiveBlog({
          page: 0,
          display: 48,
          id: process.env.AUTO_LIVE_BLOG,
        })
      ),
    ]);

    topicListData = {
      ...topicListData,
      content: extractTopics(topicListData?.content) ?? [],
    };
    let headerCache = homepageData?.cache;
    setPageTTL(ctx, headerCache?.akamai_ttl);
    let constituencyListData = null;
    let constituencyList;
    if (electionConfigData.autoLiveBlog) {
      let constituencyUrl = `https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/delhi-state-master.json`;
      await fetch(constituencyUrl).then(async (data) => {
        const data1 = await data.json();
        constituencyListData = data1;
      });
      let activeBlogs = liveBlogData?.content?.map((item) => {
        return item?.title_short.split(",")[0];
      }); //list of names for constiuencies with liveblogs
      constituencyList =
        constituencyListData?.data?.filter((item) => {
          if (activeBlogs?.includes(item?.cons_name)) {
            item.blogUrl = liveBlogData?.content?.find((blog) =>
              blog?.title_short?.includes(item?.cons_name)
            )?.canonical_url;
            item.cons_name_en = item?.cons_name + " Election Result Live";
            return item;
          }
        }) || null; //filtered list of constits with running liveblogs
    } else {
      constituencyList = null;
    }

    return {
      props: {
        breakingNewsData,
        homepageData,
        shortVideoData,
        topicListData,
        itemListData,
        statesNewsListData ,
        electionLiveData: electionLiveData ? electionLiveData : null,
        liveBlogData,
        constituencyList,
      },
    };
  } catch (error) {
    logger.error(error);
    return {
      props: {
        breakingNewsData: null,
        homepageData: null,
        shortVideoData: null,
        topicListData: null,
        itemListData: null,
        statesNewsListData: null,
      },
    };
  }
};
